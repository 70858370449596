const relatedBlogsWidgetStyle = {
  root: {
    // maxWidth: 400,
    textAlign: "left",
    margin: "50px 0",
    borderRadius: "12px",
  },
  media: {
    height: 140,
  },
};

export default relatedBlogsWidgetStyle;